import React from 'react';

class Ejercicios extends React.Component {
    render() {
        return (
            <h1>Lista de Ejercicios</h1>
        );
    }
}

export default Ejercicios;